import React from "react";
// Customizable Area Start
import { Box, Button, Typography } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import AcceptOrDeliverModal from "./AcceptOrDeliverModal.web";
import DeclineOrderModal from "./DeclineOrderModal.web";
import OpenBakeryModal from "./OpenBakeryModal.web";

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.openBakeryOpenModal = this.openBakeryOpenModal.bind(this);
    this.closeBakeryOpenModal = this.closeBakeryOpenModal.bind(this);
    this.openStore = this.openStore.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  renderDeliveredOrders = () => {
    return this.state.orders.delivered?.map(order => (
      <Card
        key={order.id}
        variant="outlined"
        data-test-id={'newModal'}
        onClick={() => this.openAcceptOrDeliverModal(
          order.status === "delivered" ? 'deliveredd' : 'delivered',
          order.id
        )}
        sx={{
          display: 'flex',
          backgroundColor: "#AE7300",
          borderRadius: "8px",
          width: "100%",
          color: "#ffffff",
          marginBottom: "10px"
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 700 }} variant="subtitle1">
                Order #{order.id}
              </Typography>
              {this.renderOrderStatusChip(order.status)}
            </Box>
          </CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', padding: "16px" }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", alignItems: 'center' }}>
              <Typography sx={{ fontSize: "12px", color: "#D6D3D1" }} variant="subtitle1" color="text.secondary" component="div">
                Client: &nbsp;
              </Typography>
              <Typography variant="subtitle1" sx={{ fontSize: "12px" }} component="div">
                James Hetfield
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    ));
  }

  renderOrderStatusChip = (status: string) => {
    return status === "delivered" ? (
      <Chip label="Delivered" sx={{ color: "#ffffff", backgroundColor: "#55D9B4" }} />
    ) : (
      <Chip label="Waiting for client" sx={{ color: "#ffffff", backgroundColor: "#01BAEC" }} />
    );
  }

  renderOrders = (orders: any, tab: any, type: any, onClick: any) => {
    return orders?.map((order: any) => (
      <Card
        key={order.id}
        sx={{
          display: 'flex',
          width: "100%",
          backgroundColor: type === 'accept' ? "#292524" : "#009168",
          borderColor: "#C2B30A",
          borderRadius: "8px",
          color: "#ffffff",
          marginBottom: "10px"
        }}
        variant="outlined"
        onClick={() => onClick(order.id)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 700, fontSize: "14px" }} variant="subtitle1">
                Order #{order.id}
              </Typography>
              <Chip label="New" sx={{ backgroundColor: "#C2B30A", color: "#ffffff" }} />
            </Box>
          </CardContent>
          <Box sx={{
            padding: "16px",
            display: 'flex',
            flexDirection: 'row',
            justifyContent: "space-between",
            alignItems: 'center',
          }}>
            <Box sx={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: "space-around",
              alignItems: 'center'
            }}>
              <Typography variant="subtitle1" sx={{ color: "#D6D3D1", fontSize: "12px" }} color="text.secondary" component="div">
                Client: &nbsp;
              </Typography>
              <Typography variant="subtitle1" sx={{ fontSize: "12px" }} component="div">
                James Hetfield
              </Typography>
            </Box>
            {this.state.tab === "two" &&
              <Typography variant="subtitle1" component="div" sx={{ fontSize: "12px" }}>
                {this.formatTime1(order.timeLeft)}
              </Typography>
            }
          </Box>
        </Box>
      </Card>
    ));
  }

  renderStoreClosed = () => (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      p: 3,
      gap: '15px',
      minHeight: '50vh'
    }}>
      <img src={require("../assets/bakery-close.svg")} />
      <Typography variant="h6" sx={{
        fontSize: '24px',
        fontWeight: 700,
      }}>
        Your&nbsp;
        <span style={{ color: "#C2B30A" }}>
          Bakery&nbsp;
        </span>
        <span style={{ color: "#ffffff", marginLeft: "3px" }}>
          is closed
        </span>
      </Typography>
      <Typography variant="body1">
        Your store is currently closed. Please open it to <br />
        start receiving orders.
      </Typography>
      <Button
        onClick={this.openBakeryOpenModal}
        data-testid="opnBtn"
        sx={{
          width: '343px',
          height: '55px',
          padding: '16px 24px',
          borderRadius: '8px',
          color: '#ffffff',
          backgroundColor: '#C2B30A',
          '&:hover': {
            backgroundColor: '#a59a00',
          },
          fontSize: '18px',
          fontWeight: 700,
          textTransform: 'none'
        }}
      >Open Store</Button>
      <OpenBakeryModal close={false} handleOpen={this.openBakeryOpenModal} open={this.state.isBakeryOpen} handleClose={this.closeBakeryOpenModal} handleClose1={false} openStore={this.openStore} closeStore={null} />
    </Box>
  );

  renderContent = () => {
    if (!this.props.isStoreOpen) {
      return this.renderStoreClosed();
    }

    const ordersEmpty = this.state.orders?.to_accept?.length === 0 && this.state.orders.preparing.length === 0 && this.state.orders.delivered.length === 0;

    if (ordersEmpty) {
      return (
        <Typography variant="body1" sx={{ display: 'flex', height: "80%", justifyContent: "center", alignItems: "center", textAlign: 'center' }}>
          Here you will see your new customer orders. Accept them to confirm that <br /> you have started preparing them.
        </Typography>
      );
    }

    return (
      <Box sx={{
        p: 3,
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: 'center',
        height: '70%',
        scrollbarWidth: 'none',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}>
        <Grid container spacing={2} sx={{ height: "100%" }} >
          <Grid item xs={4} sm={4}>
            {this.state.tab === 'one' && this.renderOrders(this.state.orders.to_accept, 'one', 'accept', this.openAcceptOrDeliverModal.bind(this, 'accept'))}
          </Grid>
          <Grid item xs={4} sm={4}>
            {this.state.tab === 'two' && this.renderOrders(this.state.orders1.preparing, 'two', 'deliver', this.openAcceptOrDeliverModal.bind(this, 'deliver'))}
          </Grid>
          <Grid item xs={4} sm={4} sx={{ height: '100%' }}>
            {this.state.tab === 'three' && this.renderDeliveredOrders()}
          </Grid>
        </Grid>
        <AcceptOrDeliverModal orderData={this.state.orderData} openDeclineModal={this.openDeclineModal} type={this.state.type} handleOpen={this.openAcceptOrDeliverModal} getOrdersList={this.getOrdersList} open={this.state.isAcceptOrDeliver} handleClose={this.closeAcceptOrDeliverModal} orders={this.state.orders} orderId={this.state.orderId} status={""} />
        <DeclineOrderModal closeAcceptOrDeliverModal={this.closeAcceptOrDeliverModal} handleOpen={this.openDeclineModal} open={this.state.isDecline} handleClose={this.closeDeclineModal} orders={this.state.orders} orderId={this.state.orderId} />
      </Box>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box sx={{ width: '100%', height: "100%", overflowY: 'hidden', overflowX: 'hidden' }}>
        <Typography variant="h6" sx={{
          fontSize: '28px',
          fontWeight: 700,
        }}>
          <span style={{ color: "#ffffff", marginLeft: "3px" }}>
            Dashboard
          </span>
        </Typography>
        <Tabs
          value={this.props.isStoreOpen ? this.state.tab : null}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: this.getTabIndicatorColor(),
            }
          }}
          onChange={(e, v) => this.handleChange(e, v)}
          aria-label="secondary tabs example"
          centered
          variant="fullWidth"
          data-test-id="tab"
        >
          <Tab value="one"
            disabled={!this.props.isStoreOpen}
            sx={{
              color: '#ffffff',
              textTransform: 'none',
              fontWeight: '400',
              height: '100%',
              '&.Mui-disabled': {
                color: '#78716C',
                backgroundColor: '#44403C',
                opacity: 1,
                pointerEvents: 'none',
              },
              '&.Mui-selected': {
                fontWeight: '700',
                color: '#ffffff',
              },
            }} label="To Accept" />
          <Tab value="two"
            disabled={!this.props.isStoreOpen}
            sx={{
              height: '100%',
              color: '#ffffff',
              fontWeight: '400',
              textTransform: 'none',
              '&.Mui-disabled': {
                pointerEvents: 'none',
                backgroundColor: '#44403C',
                color: '#78716C',
                opacity: 1,
              },
              '&.Mui-selected': {
                fontWeight: '700',
                color: '#ffffff',
              },
            }} label="Preparing" />
          <Tab value="three"
            disabled={!this.props.isStoreOpen}
            sx={{
              color: '#ffffff',
              height: '100%',
              textTransform: 'none',
              fontWeight: '400',
              '&.Mui-disabled': {
                color: '#78716C',
                pointerEvents: 'none',
                backgroundColor: '#44403C',
                opacity: 1,
              },
              '&.Mui-selected': {
                fontWeight: '700',
                color: '#ffffff',
              },
            }} label="Delivered" />
        </Tabs>

        {this.renderContent()}
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
