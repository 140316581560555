import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: { push: any };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tab: string;
  notifications: any[],
  orderData: any[],
  type: string,
  isAcceptOrDeliver: boolean,
  orders: {},
  orderId: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: any = "";
  markAsReadCallId: any = "";
  deleteCallId: any = "";
  getOrderNotificationApiCallId: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tab: "one",
      notifications: [],
      orderData: [],
      type: "",
      isAcceptOrDeliver: false,
      orders: {},
      orderId: "",

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    super.componentDidMount();
    this.getNotifications();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
  
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if (apiRequestCallId === this.getDataCallId) {
      this.handleNotifications(responseJson);
    } else if (apiRequestCallId === this.markAsReadCallId) {
      this.getNotifications();
    } else if (apiRequestCallId === this.getOrderNotificationApiCallId) {
      this.handleOrderNotification(responseJson);
    }

    // Customizable Area End
  }

  handleChange = (event: any, newValue: string) => {
    this.setState({ tab: newValue });
  };

  getNotifications = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNotification}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  goToOrders = (id: any) => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrderNotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderNotication}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  markAllRead = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.markAsReadCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markAllRead}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod1
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  calculateRelativeTime(createdAt: string) {
    const now = new Date();
    const notificationDate = new Date(createdAt);
    const timeDiff = Math.abs(now.getTime() - notificationDate.getTime());

    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (minutesDiff < 60) {
      return `${minutesDiff} minute${minutesDiff !== 1 ? 's' : ''} ago`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff} hour${hoursDiff !== 1 ? 's' : ''} ago`;
    } else {
      return `${daysDiff} day${daysDiff !== 1 ? 's' : ''} ago`;
    }
  }

  handleNotifications(responseJson: any) {
    let user_type = localStorage.getItem("user_type");
    const endpoint = window.location.pathname;
  
    const notifications = responseJson.data.map((notification: any) => {
      const { notifiable, customer, chef, created_at, is_read, id, contents, pickup } = notification.attributes;
      const orderId = (contents.match(/#(\d+)/) || [])[1] || null;
  
      let status = notifiable.status;
      if (notifiable.status === "waiting_for_client") {
        status = endpoint === "/notifications" ? "waiting for client" : "waiting to pickup";
      }
  
      const pickupTimeFormatted = pickup?.pickup_time
        ? new Date(pickup.pickup_time).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true
          }).toLowerCase().replace(" ", "")
        : null;
  
      return {
        id: notifiable.id,
        notificationId: id,
        first_name: user_type === "chef" ? customer.first_name : chef.first_name,
        last_name: user_type === "chef" ? customer.last_name : chef.last_name,
        created_at,
        is_read,
        orderId,
        status,
        pickup_time: pickupTimeFormatted
      };
    }).sort((a: any, b: any) => Date.parse(b.created_at) - Date.parse(a.created_at));
  
    this.setState({ notifications });
  }
  
  
  handleOrderNotification(responseJson: any) {
    localStorage.setItem("path", "DASHBOARD");
    localStorage.setItem("isAcceptOrDeliver", JSON.stringify(true));
    
    const notification = responseJson.data;
    const { notifiable, customer, chef, is_read, pickup, order_item, catalogue_image } = notification.attributes;
    const { price, catalogue } = order_item;
    const quantity = responseJson.data.attributes.order_item.quantity
    const { name, price: item_price, extras, order_items_count } = catalogue.data.attributes;
    
    const notifications = [{
      id: notifiable.id,
      notificationId: notification.id,
      isRead: is_read,
      pickupTime: pickup.pickup_time,
      notifiable: {
        status: notifiable.status,
        customerName: `${customer.first_name} ${customer.last_name}`,
        chefName: `${chef.first_name} ${chef.last_name}`,
      },
      catalogueImages: catalogue_image.map((image: any) => image.url),
      quantity,
      price,
      name,
      item_price,
      extras: extras.map((extra: any) => ({ name: extra.name, price: extra.price })),
      order_items_count,
    }];
    
    this.setState({ orders: notifications });
    localStorage.setItem("data", JSON.stringify(notifications));
    this.props.history.push("/dashboard");
  }
  

}

