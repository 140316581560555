import React from "react";

// Customizable Area Start
import { Typography, Button, InputLabel, Alert, Box,Collapse} from "@mui/material";
import { Link } from 'react-router-dom';

import { StyleSheet } from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { image1, image2, image3 } from "./assets";

// Customizable Area End

import LoginController, {
  Props,
} from "./LoginController";

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <div style={styles.main}>
       
        <div style={styles.wrapper}>
          <img style={styles.banner} src={require("../assets/banner.png")} />
          <div style={{
            content: '',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
            zIndex: 1
          }}></div>
        </div>
        <div style={styles.image1}>
          <img src={image1} alt="image1" />
        </div>
        <div style={styles.image2}>
          <img src={image2} alt="image2" />
        </div>
        <div style={styles.image3}>
          <img src={image3} alt="image3" />
        </div>
        <div style={styles.body}>
          <div style={{ display: 'flex', flexDirection: "column" as const, alignItems: 'center', marginTop: '20px' }}>
            <img width={71} height={50} src={require("../../../components/src/logo.svg")} />
            <Typography variant="h5" style={{ color: "#C2B30A", fontWeight: "700", textTransform: "uppercase", marginTop: '8px', letterSpacing: "3px" }}>
              AMATEUR BAKERS
            </Typography>
          </div>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required("Email is required"),
              password: Yup.string()
                .required("Password is required")
                .min(5, "Password must be minimum 5 characters"),
            })}
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={(values: { email: "", password: "" }) => {
              this.doEmailLogIn(values);
            }}
            data-testid="loginForm"
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              setFieldTouched,
              touched,
              values
            }) => (
              <Box style={styles.formWrapper}>
                <Typography style={{ fontSize: "1.5rem", color: "#C2B30A", fontWeight: "700", textTransform: "none",}} sx={ {m : 0}}>
                  Login
                </Typography>
                <Collapse in={this.state.showToast}>
                    <Alert
                      severity={this.state.toastSeverity}
                      style={{
                        display:"flex",
                        backgroundColor: '#FECFCF',  
                        color: '#DC2626',            
                        border: '1px solid #FECFCF', 
                        borderRadius: '4px',         
                        fontWeight: 700,             
                        padding: "8px 16px",         
                        width: '343px',              
                        marginBottom: '16px',        
                        textAlign: "center",  
                        justifyContent:"center",
                        alignItems:"center",    
                      }}
                      iconMapping={{
                        error: <HighlightOffRoundedIcon fontSize="inherit" style={{ color: '#d32f2f' }} />,  
                      }}
                    >
                      {this.state.toastMessage}
                    </Alert>
                  </Collapse>
                  <div style={styles.inputWrapper}>
                  <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Email</InputLabel>
                  <OutlinedInput
                    fullWidth
                    style={styles.inputLabel}
                    id="outlined-adornment-email"
                    type={'text'}
                    placeholder={"Email"}
                    value={values.email}
                    onChange={handleChange("email")}
                    data-test-id='emailInput'
                  />
                  {errors.email && touched.email && (
                    <span style={styles.errorStyle}>
                      {errors.email}
                    </span>
                  ) }
                </div>
                <div style={styles.inputWrapper}>
                  <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Password</InputLabel>
                  <OutlinedInput
                    data-test-id='passwordInput'
                    fullWidth
                    style={styles.inputLabel}
                    id="outlined-adornment-password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder={"Password"}
                    value={values.password}
                    onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.handleClickShowPassword()}
                          edge="end"
                          data-testid="passIcon"
                        >
                          {!this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.password && touched.password && (
                    <span style={styles.errorStyle}>
                      {errors.password}
                    </span>
                  )}
                </div>
                <div style={styles.forgotPassLabel}>
                <Link 
                    to="/ForgotPassword" 
                    style={{ ...styles.forgotPassword, marginTop: "-16px", position: 'relative', textDecoration: 'none' }} 
                    color="inherit"
                  >
                    <span style={{ textDecoration: 'underline', textDecorationColor: 'inherit', textDecorationThickness: '1px', textUnderlineOffset: '4px' }}>
                      Forgot your password?
                    </span>
                  </Link>
                </div>
                <div
                  style={styles.buttonsWrapper}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    data-test-id={"btnLogin"}
                    onClick={() => handleSubmit()}
                    style={styles.button}
                  >Log in
                  </Button>
                </div>
                <div
                  style={styles.notHaveAccount}
                >
                  <Typography variant="body1" color={"#ffffff"} fontSize={"12px"} >
                    Don't have an account yet?
                  </Typography>
                  <Link to={this.SignupPage()} style={{...styles.forgotPassword,fontSize:"12px"}} color="inherit">
                    Create one
                  </Link>
                </div>
                <div
                  onClick={this.goToRolesLogin}
                  style={styles.arrowWrapper}
                  data-test-id="backBtn"
                >
                  <img
                    src={require('../assets/back_arrow.png')}
                    style={styles.backArrow}
                  />
                  <Typography style={styles.noAccountLabel} >
                    Back
                  </Typography>
                </div>
              </Box>
            )}
          </Formik>
        </div>
      </div>
      // Customizable Area End
    );
  }

}
// Customizable Area Start
const styles = StyleSheet.create({
  image1: {
    position: "absolute",
    top: 0,
    left: "50%"
  },
  image2: {
    position: "absolute",
    right: 0,
    top: 0
  },
  image3: {
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 0
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000000",
    fontSize: 25,
  },
  wrapper: {
    flex: 1,
    position: "relative",
  },
  banner: {
    width: "100%", height: "100vh"
  },
  gradient: {
    width: '100%',
    height: '100%',
  },
  body: {
    flex: 1,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    width: "40%",
  },
  eyeIcon: {
    height: "25px",
    width: "25px"
  },
  input: {
    borderRadius: 8,
    backgroundColor: "#57534E",
    borderColor: "#000000",
  },
  inputLabel: {
    color: "#FFFFFF",
    // padding: "5px 15px",
    borderRadius: 8,
    backgroundColor: "#57534E",
  },
  textFont: {
    fontFamily: "Cerebri Sans",
    color: "#55D9B4",
    fontSize: 32,
    fontWeight: "700",
    lineHeight: 3,
    textAlign: "left",
  },
  forgotPassLabel: { display: "flex", justifyContent: "center", alignItems: "center" },
  formWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as const,
    width: "100%",
    paddingTop: "10px"
  },
  inputWrapper: {
    flex: 1,
    marginBottom: "20px",
    width: "50%"
  },
  paddingBottom: {
    paddingBottom: "0px"
  },
  removeBackground: {
    backgroundColor: "transparent",
    height: "10px"
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    // padding: "20px",
    paddingTop: "0px",
    paddingBottom: "0px",
    width: "50%"
  },
  notHaveAccount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF"
  },
  logo: {
    height: 80,
    width: 80,
    marginTop: "50px",
    marginBottom: "25px",
  },
  backArrow: {
    height: 30,
    width: 30,
  },
  arrowWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    paddingTop:"0"
  },
  button: {
    backgroundColor: "#C2B30A",
    color: "#ffffff",
    padding: "15px",
    fontWeight: "700",
    textTransform: 'none'
  },
  forgotPassword: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: "700",
    fontSize: 13,
    lineHeight: 5,
    textAlign: "left" as const,
    color: "#FFFFFF",
    marginLeft: 5,
  },
  noAccountLabel: {
    fontSize: 16,
    color: "#ffffff",
    fontWeight: "700"
  },
  errorStyle: {
    color: "red",
    textAlign: "center" as const,
    fontSize: 12
  },
});
// Customizable Area End