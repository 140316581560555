import React from "react";
// Customizable Area Start
import { cartIcon } from "./assets";
import { Typography, Button } from "@mui/material"
import { Link } from 'react-router-dom';
// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
  ShoppingCartOrder,
  ShoppingCartOrderItem,
} from "./ShoppingCartOrdersController";

export default class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div style={{ backgroundColor: "rgba(0, 0, 0, 0.9)", position: "absolute", right: 0, top: 0, zIndex: 1000 }}>
        <div style={styles.cartContainer}>
          <div style={styles.cartHeader}>
            <Typography>
              <h2>
                <span style={styles.highlight}>Your</span> Order
              </h2>
            </Typography>
            <button onClick={this.props.onClose} style={styles.closeBtn}>×</button>
          </div>
          <div style={styles.cartItems}>
            {this.state.cartItems.length === 0 ?
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <img style={{ width: "56px" }} src={cartIcon} alt="icon" />
                <Typography style={{ fontSize: "18px", fontWeight: 700, margin: "20px", textAlign: "center" }}>
                  You don't have any added <span style={{ color: "#C2B30A" }}>products</span> <br /> yet.
                </Typography>
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                  When you select your dishes will appear here
                </Typography>
              </div> :
              <>
                {this.state.cartItems.map((item) => (
                  <div key={item.id} style={styles.cartItem}>
                    <img
                      src={item?.imageUrl?.[0]?.url || 'https://via.placeholder.com/60'}
                      alt={item.name}
                      style={styles.itemImage}
                    />
                    <div style={styles.itemDetails}>
                      <Typography>
                        <h3 style={{ margin: 0 }}>{item.name}</h3>
                      </Typography>
                      <Typography>

                        <p style={{ fontSize: "12px" }}>{item.description}</p>
                        <div style={styles.itemPrice}>
                          <span style={styles.discountedPrice}>
                            ${item.price.toFixed(2)}
                          </span>
                        </div>
                      </Typography>

                      <div style={styles.quantityControlsContainer}>
                        <div style={styles.quantityControls} data-test-id="quantity-controlls">
                          <button
                            onClick={() => this.updateQuantityLocally(item.id, item.quantity - 1)}
                            style={styles.quantityButton}
                            data-test-id="decrement"
                          >
                            -
                          </button>
                          <span>{item.quantity}</span>
                          <button
                            onClick={() => this.updateQuantityLocally(item.id, item.quantity + 1)}
                            style={styles.quantityButton}
                            data-test-id="increment"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            }
          </div>
          <div style={styles.cartFooter}>
            <div style={styles.totalContainer}>
              <Typography style={{ padding: "0 20px 0 0" }}>
                <h4>Total: ${this.calculateTotal().toFixed(2)}</h4>
              </Typography>
            </div>
            <div>
              <Link to={this.state.cartItems.length === 0 ? "#" : "/menu/UserPayments"} style={{ textDecoration: "none" }}>
                <Button
                  onClick={() => {
                    if (this.state.cartItems.length > 0) {
                      this.handlecheckout();
                    }
                  }}
                  variant="contained"
                  data-test-id="checkout-button"
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: this.state.cartItems.length === 0 ? "#57534E" : "#C2B30A",
                    color: this.state.cartItems.length === 0 ? "#A8A29E" : "white",
                    height: "55px",
                    width: "200px",
                    textTransform: "none",
                    fontSize: "18px",
                    "&:hover": {
                      backgroundColor: this.state.cartItems.length === 0 ? "#57534E" : "#C2B30A",
                    }
                  }}
                >
                  Go to Checkout
                </Button>
              </Link>
            </div>

          </div>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
  cartContainer: {
    backgroundColor: "black",
    color: "white",
    maxWidth: "400px",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    float: "right",
    fontFamily: "Roboto",
    width: "400px"
  },
  cartHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
  },
  highlight: {
    color: "yellow",
  },
  closeBtn: {
    background: "none",
    border: "none",
    fontSize: "24px",
    color: "white",
    cursor: "pointer",
  },
  cartItems: {
    marginTop: "20px",
    flex: 1,
    padding: "20px",
    overflowY: "auto"
  },
  cartItem: {
    display: "flex",
    marginBottom: "20px",
    borderBottom: "1px solid #555",
    paddingBottom: "20px",
  },
  itemImage: {
    width: "80px",
    height: "80px",
    borderRadius: "8px",
    marginRight: "10px",
  },
  itemDetails: {
    flex: 1,
  },
  itemPrice: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  discountedPrice: {
    fontSize: "18px",
    color: "white",
  },
  quantityControlsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  quantityControls: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    justifyContent: "space-around",
    backgroundColor: "#888",
    width: "100px",
    borderRadius: "10px",
  },
  quantityButton: {
    backgroundColor: "#888",
    color: "white",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
  },
  totalContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  cartFooter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0 50px 0",
    backgroundColor: "#292524",
    borderRadius: "16px 16px 0 0"
  },
  checkoutBtn: {
    backgroundColor: "#f8b400",
    color: "black",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "5px",
  },
}
// Customizable Area End
