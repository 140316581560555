Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.editdashboarApiMethodType = "PATCH";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.ordersUrl = "/bx_block_shopping_cart/orders";
exports.editordersUrl = "bx_block_shopping_cart/orders";
exports.orderItem = 'bx_block_shopping_cart/order_items/cart_items'
exports.getProfile = "account_block/logged_user";
exports.validationApiContentType = "application/json";
exports.getAddresses = 'bx_block_address/addresses';
exports.updateMethodType = "PUT";
exports.updateStore = 'bx_block_order_management_upi_payment/merchants'
exports.testOrderItemsData =  [
    {
      id: "123",
      type: "order_item",
      attributes: {
        id: 1,
        price: 15.99,
        quantity: 2,
        taxable: true,
        taxable_value: 31.98,
        other_charges: 2.5,
        extras: [
          {
            id: 101,
            catalogue_id: 500,
            name: "Extra Cheese",
            price: "1.50",
            created_at: "2024-11-01T12:00:00Z",
            updated_at: "2024-11-01T12:00:00Z"
          },
          {
            id: 102,
            catalogue_id: 500,
            name: "Spicy Sauce",
            price: "0.75",
            created_at: "2024-11-01T12:00:00Z",
            updated_at: "2024-11-01T12:00:00Z"
          }
        ],
        time_to_pick_up: "2024-11-18T18:30:00Z",
        catalogue: {
          data: {
            id: "500",
            type: "catalogue",
            attributes: {
              id: 500,
              name: "Cheese Pizza",
              description: "A delicious cheese pizza with a crispy crust.",
              availability: "available",
              weight: 350,
              currency: "USD",
              price: 12.99,
              recommended: true,
              on_sale: false,
              sale_price: null,
              discount: null,
              manufacture_date: null,
              extras: [
                {
                  id: 103,
                  catalogue_id: 500,
                  name: "Garlic Butter",
                  price: "1.25",
                  created_at: "2024-11-01T12:00:00Z",
                  updated_at: "2024-11-01T12:00:00Z"
                }
              ],
              dish_of_the_day: false,
              account_id: 250,
              estimated_time: "20",
              menu_selection: "Main Course",
              spicy_level: 1,
              created_at: "2024-11-01T10:00:00Z",
              updated_at: "2024-11-01T12:00:00Z",
              categories: [
                {
                  id: "30",
                  type: "category",
                  attributes: {
                    id: 30,
                    name: "Pizza",
                    description: "All types of pizza",
                    created_at: "2024-08-15T12:00:00Z",
                    updated_at: "2024-08-15T12:00:00Z"
                  }
                }
              ]
            }
          }
        }
      }
    }
  ]
exports.categoriesUrl = "/bx_block_categories/categories";
exports.bakerProfile = "/account_block/public_baker_profile";
exports.categoriesUrltwo = "/account_block/accounts";
exports.categoriesUrlfour = "/account_block/accounts";
exports.categoriesUrlthree = "/bx_block_categories/categories";
exports.showcategoriesbelowtabs = "/bx_block_catalogue/categorized_dishes";
exports.changestoreApiEndPoint = "/bx_block_order_management_upi_payment/merchants";
exports.storeApiMethodType = "PUT";
exports.mostPopularDishesEndPoint = "bx_block_catalogue/most_popular_dishes";
exports.mostPopularDisheDetailsEndPoint = "/bx_block_catalogue/catalogues";
exports.popularSpecialities = "/bx_block_catalogue/catalogues"
exports.url = "https://devakasbc-436788-react.b436788.dev.eastus.az.svc.builder.cafe"
exports.addToCart = "POST"
exports.addToCartApi = "/bx_block_shopping_cart/order_items"
// Customizable Area End