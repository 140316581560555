import React from "react";

// Customizable Area Start
import {
    Typography,
    Button
} from "@mui/material";

// Customizable Area End

import UserOrderHistoryController, {
    Props,
} from "./UserOrderHistoryController.web";

export default class UserOrderDetails extends UserOrderHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <div style={styles.main}>
                <div> 
                    <div style={styles.cartContainer}>
                        <div style={styles.cartHeader}>
                            <Typography variant="h4" style={{ fontWeight: 700 }}>
                                <span style={styles.highlight}>Orders</span> history
                            </Typography>

                        </div>
                        <div style={styles.cartItems}>
                            {this.state.cartItems.map((item) => (
                                <div key={item.id} style={styles.cartItem}>
                                    <img
                                        src={item?.imageUrl?.[0]?.url || 'https://via.placeholder.com/60'}
                                        alt={item.name}
                                        style={styles.itemImage}
                                    />
                                    <div style={styles.itemDetails}>
                                        <Typography variant="h5" style={{ margin: 0, fontWeight: 700 }}>{item.name}</Typography>
                                        <Typography variant="body1" style={{ fontSize: "12px" }}>{item.description}</Typography>
                                        <div style={styles.itemPrice}>
                                            <span style={styles.discountedPrice}>
                                                ${item.price.toFixed(2)}
                                            </span>
                                        </div>

                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#57534E",
                                                color: "#fff",
                                                width: "128px",
                                                borderRadius: "8px",
                                                textTransform: "none",
                                                "&:hover": {
                                                    backgroundColor: "#6C6962",
                                                },
                                            }}
                                        >
                                            {item.quantity}
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <Typography variant="h5" style={{ fontWeight: 700 }}>
                                Cost Summary
                            </Typography>
                            <div style={{ margin: "20px" }}>

                                <Typography variant="body1" style={{ display: "flex", justifyContent: "space-between", width: "20%" }}>
                                    <div>
                                        Products
                                    </div>
                                    <div>${this.state.costSummary.products.toFixed(2)}</div>
                                </Typography>
                                <Typography variant="body1" style={{ display: "flex", justifyContent: "space-between", width: "20%" }}>
                                    <div>
                                        Tips
                                    </div>
                                    <div>${this.state.costSummary.tips.toFixed(2)}</div>
                                </Typography>
                                <Typography variant="body1" style={{ display: "flex", justifyContent: "space-between", width: "20%" }}>
                                    <div>
                                        Fee
                                    </div>
                                    <div>${this.state.costSummary.fee.toFixed(2)}</div>
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
    cartContainer: {
        backgroundColor: "rgba(41, 37, 36, 1)",
        color: "white",
        padding: "20px",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Roboto",
    },
    cartHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    highlight: {
        color: "yellow",
    },
    cartItems: {
        marginTop: "20px",
        flex: 0.7,
        maxHeight: "100%"
    },
    cartItem: {
        display: "flex",
        marginBottom: "20px",
        borderBottom: "1px solid #555",
        paddingBottom: "20px",
    },
    itemImage: {
        width: "80px",
        height: "80px",
        borderRadius: "8px",
        marginRight: "10px",
    },
    itemDetails: {
        flex: 1,
        marginLeft: "10px"
    },
    itemPrice: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
    },
    discountedPrice: {
        fontSize: "18px",
        color: "white",
    },
    main: {
        height: "100%",
        width: "100%"
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "40%",
        textTransform: "capitalize"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        marginBottom: "20px",
        borderRadius: "10px",
        marginTop: "30px"
    },
};
// Customizable Area End