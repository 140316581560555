import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");

interface OrderItemResponse {
  order_items: OrderItem[];
  order_status: string;
}

// Order item object
export interface OrderItem {
  id: string;
  type: 'order_item';
  attributes: OrderItemAttributes;
}

// Attributes of the order item
interface OrderItemAttributes {
  id: number;
  price: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  other_charges: number;
  extras: Extra[];
  order_created_at: string
  time_to_pick_up: string | null;
  catalogue: Catalogue;
  customer: {
    data: Account;
  };
}
type Account = {
  id: string;
  type: "account";
  attributes: {
    country_cc: string;
    state: string;
    zipcode: number;
    activated: boolean;
    country_code: string;
    email: string;
    first_name: string;
    profile_pic: string | null;
    insta_link: string | null;
    facebook_link: string | null;
    full_phone_number: string;
    last_name: string;
    phone_number: string;
    type: "EmailAccount";
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
    working_days: string;
    opening_hours: Record<string, any>;
    addresses: {
      data: Address[];
    };
  };
};
type Address = {
  id: string;
  type: "address";
  attributes: {
    latitude: number;
    longitude: number;
    address: string;
    address_type: "Home" | "Work" | "Other";
    additional_info: string | null;
  };
};
interface Extra {
  id: number;
  catalogue_id: number;
  name: string;
  price: string;
  created_at: string;
  updated_at: string;
}
interface Catalogue {
  data: CatalogueData;
}
interface CatalogueData {
  id: string;
  type: 'catalogue';
  attributes: CatalogueAttributes;
}
interface CatalogueAttributes {
  id: number;
  name: string;
  description: string;
  availability: string;
  weight: number | null;
  currency: string | null;
  price: number;
  recommended: boolean | null;
  on_sale: boolean | null;
  sale_price: number | null;
  discount: number | null;
  manufacture_date: string | null;
  extras: Extra[];
  dish_of_the_day: boolean;
  account_id: number;
  estimated_time: string;
  menu_selection: string;
  spicy_level: number;
  created_at: string;
  updated_at: string;
  categories: Category[];
}
interface Category {
  id: string;
  type: 'category';
  attributes: CategoryAttributes;
}

interface CategoryAttributes {
  id: number;
  name: string;
  description: string | null;
  created_at: string;
  updated_at: string;
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  openbackry: any;
  BakeryClose: any;
  isStoreOpen: boolean
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tab: string;
  isAcceptOrDeliver: boolean;
  type: string;
  isDecline: boolean;
  isBakeryOpen: boolean;
  isStoreOpen: boolean;
  orders: {
    to_accept: any[],
    preparing: any[],
    delivered: any[]
  },
  orderId: any;
  orderData: OrderItem[];
  isBakeryClose: boolean;
  BakeryClose: boolean;
  status: string;
  orders1: any
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getOrdersListApiCallId: string = "";
  getOrderApiCallId: string = ''
  private timers: { [key: string]: any } = {};
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      tab: sessionStorage.getItem("tab") || "one",
      isStoreOpen: false,
      isAcceptOrDeliver: false,
      type: "",
      isDecline: false,
      isBakeryOpen: false,
      isBakeryClose: false,
      orders: {
        to_accept: [],
        preparing: [],
        delivered: []
      },
      orderId: "",
      orderData: [],
      BakeryClose: false,
      status: '',
      orders1: {}
    }
    this.timers = {};
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getOrdersList();
    const isBakeryOpen = localStorage.getItem('isBakeryOpen');
    const isStoreOpen = localStorage.getItem('isStoreOpen');
    const data = JSON.parse(localStorage.getItem("data") || "[]");
    const orderId = data.length > 0 ? data[0].id : null;
    const type = data.length > 0 ? data[0].notifiable.status : null;
    const isAcceptOrDeliver = localStorage.getItem('isAcceptOrDeliver') == "true" ? true : false;
    this.setState({
      isBakeryOpen: isBakeryOpen ? true : false,
      isStoreOpen: isStoreOpen === "true" ? true : false,
      BakeryClose: this.props.BakeryClose,
      isAcceptOrDeliver,
      orderId,
      type
    });
    setTimeout(() => {
      const isStoreOpen = localStorage.getItem('isStoreOpen');
      this.setState({
        isStoreOpen: isStoreOpen === "true" ? true : false,
      });
    }, 100);
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getOrdersListApiCallId) {
      this.handleOrderList(responseJson)
    } else if (apiRequestCallId === this.getOrderApiCallId) {
      const typedResponse = responseJson as OrderItemResponse;
      this.setState({ orderData: typedResponse.order_items, status: typedResponse.order_status })
    }
    // Customizable Area End
  }
  // Customizable Area Start

  getOrdersList = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrdersListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordersUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  async componentWillUnmount() {
    this.clearAllTimers();
  }

  handleOrderList = (responseJson: any) => {
    this.setState(
      { orders: responseJson, orders1: this.initializeOrders(responseJson) },
      () => {
        this.startAllTimers();
        const hasPreparingOrders = this.state.orders.preparing.length > 0;
        const hasPendingPickupOrders = this.state.orders.delivered.some(order => order.status === "waiting_for_pickup");
        const showBanner = hasPreparingOrders || hasPendingPickupOrders;
        localStorage.setItem("showorderInProgress", JSON.stringify(showBanner));
      }
    );
  }
  initializeOrders = (orders: any) => {
    const updatedOrders: Record<string, any[]> = {};
    Object.keys(orders).forEach((category) => {
      updatedOrders[category] = orders[category].map((order: any) => ({
        ...order,
        timeLeft: this.getSecondsFromString(order.pickup_time),
      }));
    });
    return updatedOrders;
  };

  getSecondsFromString = (pickupTime: any) => {
    if (!pickupTime) return 0;
    const minutes = parseInt(pickupTime, 10) || 0;
    return minutes * 60;
  };

  startAllTimers = () => {
    this.clearAllTimers();
    this.timers = {};
  
    Object.keys(this.state.orders1).forEach((category) => {
      this.state.orders1[category].forEach((order: any) => {
        this.timers[order.id] = setInterval(() => {
          this.setState((prevState) => {
            const updatedOrders = { ...prevState.orders1 };
            const orderIndex = updatedOrders[category].findIndex((o: any) => o.id === order.id);
            if (orderIndex !== -1 && updatedOrders[category][orderIndex].timeLeft > 0) {
              updatedOrders[category][orderIndex].timeLeft -= 1;
            }
            return { orders1: updatedOrders };
          });
        }, 1000);
      });
    });
  };
  
  clearAllTimers = () => {
    Object.values(this.timers).forEach(clearInterval);
    this.timers = {}; 
  };
  
  formatTime1 = (timeLeft: any) => {
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  getOrderItem = (id: string) => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.orderItem}?order_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  openAcceptOrDeliverModal = (type: string, id: string) => {
    this.setState({ isAcceptOrDeliver: true, type, orderId: id });
    this.getOrderItem(id);
  };

  openDeclineModal = () => {
    this.setState({ isDecline: true });
  };

  openBakeryOpenModal = () => {
    this.setState({ isBakeryOpen: true });
  };

  closeAcceptOrDeliverModal = () => {
    this.setState({ isAcceptOrDeliver: false, orderData: [] });
    localStorage.removeItem("data")
    localStorage.removeItem("isAcceptOrDeliver")
  };

  closeDeclineModal = () => {
    this.setState({ isDecline: false });
  };

  closeBakeryOpenModal = () => {
    this.setState({ isBakeryOpen: false });
  };

  openStore = () => {
    this.setState({ isStoreOpen: true }, () => {
      this.props.openbackry(true);
      localStorage.setItem('isStoreOpen', JSON.stringify(true));
    });
  };

  handleChange = (event: any, newValue: string) => {
    this.setState({ tab: newValue });
  };

  formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-IN', { hour12: false, timeZone: 'Asia/Kolkata' });
  };


  getTabIndicatorColor = (): string => {
    const tabColors: Record<string, string> = {
      one: '#C2B30A',
      two: '#009168',
    };
    return tabColors[this.state.tab] ?? '#AE7300';
  };

  // Customizable Area End
}
