import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

type Address = {
  id: number;
  country: string | null;
  latitude: number | null;
  longitude: number | null;
  address: string;
  addressble_id: number;
  addressble_type: string | null;
  address_type: string;
  created_at: string; // ISO date string
  updated_at: string; // ISO date string
  additional_info: string | null;
  is_selected: boolean;
};

type Meta = {
  message: string;
  selected_address_id?: number | null;
};

type ResponseData = {
  data: Address[];
  meta: Meta;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    addresses: Address[]
    selecetedAddress: number | null
    isDeleteModal: boolean;
    isSaveModal: boolean;
    deleteAddress: number | null;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class MyAddressesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAddressesApiCallId: string = ''
    deleteAddressApiCallId: string = ''
    selectAddressApiCallId: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            
            // Customizable Area End
        ];

        // Customizable Area Start
        this.state = {
            addresses: [],
            selecetedAddress: null,
            isDeleteModal: false,
            isSaveModal: false,
            deleteAddress: null
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
              switch(apiRequestCallId){
                case this.getAddressesApiCallId : {

                    const responseJson: ResponseData = message.getData(
                        getName(MessageEnum.RestAPIResponceSuccessMessage)
                    );
                    if(responseJson.data) {
                        const selectedAddress = responseJson?.data.find(address => address.is_selected)?.id || null
                        this.setState({addresses: responseJson?.data, selecetedAddress: selectedAddress})
                    }else {
                        this.setState({addresses: []});
                    }

                    break;
                }
                case this.selectAddressApiCallId: {
                  this.handleChangeSaveModal(true);                    
                    break;
                }
                case this.deleteAddressApiCallId: {
                    this.getAddresses();
                    this.handleCloseDeleteModal();
                    break;
                }
              }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getAddresses();
    }
    handleAddNew()  {
      //move to new page
    }

    handleSelectAddress(address: number) {
        this.setState({selecetedAddress: address});
    }
    getAddresses = () => {
        let token = localStorage.getItem("authToken");
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAddressesApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAddresses
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    editSelectedAddress = () => {
        let token = localStorage.getItem("authToken");
        const header = {
          'Content-Type': 'application/json',
            "token": token
        };
        const body = {
          address : {
            selected_address_id: this.state.selecetedAddress
          }
        };
       
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.selectAddressApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getAddresses}/${this.state.selecetedAddress}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          header
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.updateMethodType
        );

    runEngine.sendMessage(requestMessage.id, requestMessage)

  }
  deleteAddress = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAddressApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAddresses}/${this.state.deleteAddress}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  handleEditAddress = (address: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({ selecetedAddress: address })
  }
  handleDeleteAddress = (address: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({ deleteAddress: address, isDeleteModal: true });
  }
  handleCloseDeleteModal = () => {
    this.setState({ isDeleteModal: false, deleteAddress: null });
  }
  handleChangeSaveModal = (isSaveModal: boolean) => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if (user.data?.attributes?.addresses) {
      const updatedAddresses = user.data.attributes.addresses.map((address: any) => ({
        ...address,
        is_selected: address.id === this.state.selecetedAddress,
      }));
      user.data.attributes.addresses = updatedAddresses;
      localStorage.setItem("user", JSON.stringify(user));
    }
    this.setState({ isSaveModal })
  }

    // Customizable Area End
}
