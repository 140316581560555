import React from "react";

// Customizable Area Start

import {
  Box,
  Typography,
  IconButton,
  TextField
} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import { downArrow } from "./assets";

// Customizable Area End

import UserOrderHistoryController, {
  Props,
} from "./UserOrderHistoryController.web";

export default class OrderDetails extends UserOrderHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <div style={styles.main}>
        <Box>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', width: "343px", height: "32px" }}>
              <TextField
                data-test-id="searchbar"
                variant="outlined"
                placeholder="Search"
                value={this.state.searchQuery}
                InputProps={{
                  style: {
                    color: 'white',
                    paddingLeft: "12px",
                    height: "34px"
                  },
                  endAdornment: (
                    <IconButton>
                      <SearchIcon style={{ color: 'white' }} />
                    </IconButton>
                  )
                }}
                InputLabelProps={{
                  style: { fontSize: '12px' }
                }}
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#57534E',
                  marginRight: '10px',
                  flex: 1,
                }}
              />
            </div>
            <div>
              <div style={{ position: 'relative' }}>
                <IconButton
                  style={{
                    height: '32px',
                    color: 'white',
                    backgroundColor: '#57534E',
                    width: '112px',
                    marginRight: '10px',
                    borderRadius: '4px',
                  }}
                >
                  <div
                    style={{
                      gap: '6px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <SortIcon style={{ width: '20px', height: '20px' }} />
                    <Typography style={{ fontSize: '12px', color: 'white', fontWeight: 400 }}>
                      Sort by
                    </Typography>
                    <img src={downArrow} alt="No-data-found" />
                  </div>
                </IconButton>
              </div>
            </div>

          </div>
          {this.state.userOrderDetails.map((order) => (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
                <Box
                  key={order.id}
                  sx={{
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 3,
                    width: "100%"
                  }}
                >
                  <img
                    style={{ width: 56, height: 56, borderRadius: '4px', objectFit: "cover" }}
                    alt="Live from space album cover"
                    src={order?.images?.[0]}
                  />
                  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          fontSize: '12px',
                        }}
                      >
                        {order.firstName}{order.lastName}
                      </Typography>
                      <Box
                        sx={{
                          width: '8px',
                          display: 'inline-block',
                          height: '8px',
                          borderRadius: '50%',
                        }}
                      />
                    </Box>
                    <List
                      sx={{
                        listStyleType: 'disc',
                        color: "#A8A29E",
                        display: "flex",
                        fontSize: '10px',
                        fontWeight: 400,
                        padding: 0,
                        width: "35%",
                      }}
                    >
                      <ListItem sx={{ padding: 0 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 400,
                            fontSize: '10px',
                          }}
                        >
                          Date: {order.orderCreatedAt}
                        </Typography>
                      </ListItem>

                      <ListItem sx={{ display: 'list-item', padding: 0 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 400,
                            fontSize: '10px',
                          }}
                        >
                          Pick up: {new Date(order.timeToPickUp).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true })}
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>
                </Box>
                <div onClick={() => this.goToOrderHistory(order.id)} data-test-id="details" style={{ marginRight: "0px" }}>
                  <ChevronRightIcon sx={{ color: "#C2B30A" }} />
                </div>
              </div>
              <Divider sx={{ borderColor: "#57534E", marginY: 2 }} />
            </div>
          ))}
        </Box>
      </div>
      // Customizable Area End
    );
  }

}
// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
  cartContainer: {
    backgroundColor: "rgba(41, 37, 36, 1)",
    color: "white",
    padding: "20px",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto",
  },
  cartHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  highlight: {
    color: "yellow",
  },
  cartItems: {
    marginTop: "20px",
    flex: 0.7,
    overflowY: "scroll",
    maxHeight: "100%"
  },
  cartItem: {
    display: "flex",
    marginBottom: "20px",
    borderBottom: "1px solid #555",
    paddingBottom: "20px",
  },
  itemImage: {
    width: "80px",
    height: "80px",
    borderRadius: "8px",
    marginRight: "10px",
  },
  discountedPrice: {
    fontSize: "18px",
    color: "white",
  },
  itemDetails: {
    flex: 1,
  },
  itemPrice: {
    gap: "10px",
    display: "flex",
    alignItems: "center",
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#C2B30A",
    fontWeight: "700",
    padding: "15px",
    borderRadius: "10px",
    marginTop: "30px",
    marginBottom: "20px",
  },
  main: {
    width: "100%",
    height: "100%",
  },
  body: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "column" as const,
    padding: "10px",
    justifyContent: "center",
    textTransform: "capitalize",
    width: "40%",
  },
};
// Customizable Area End